export function compressView(view: string): CompressedView {
  return views[view as View] ?? views.Unknown;
}

type View = keyof typeof views;

type CompressedView = (typeof views)[View];

// This serializer needs to be kept in sync with c# backend and BigQuery deserialisation.
// 'View' in this codebase, is called 'Screen' in the monolith.
// For more information, see:
// https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2354610204/ExitLabel+and+ExitPoint+encoding
export const views = {
  Unknown: "u",
  Homepage: "hp",
  LandingPage: "lp",
  AirportsNearLandingPage: "anp",
  CityLandingPage: "clp",
  ModeLandingPage: "mlp",
  NearbyHotelsLandingPage: "hnp",
  DestinationLanding: "dl",
  Retarget: "cab",
} as const;

export const viewKeys = Object.keys(views) as View[];
