import type { LanguageCode } from "src/utils/language";

export function buildExplorePathname(
  languageCode: LanguageCode,
  origin?: string,
  destination?: string,
) {
  const path = [""];
  if (languageCode !== "en") {
    path.push(languageCode);
  }

  path.push("map");

  if (origin) {
    path.push(encodeURIComponent(origin));
  }

  if (destination) {
    path.push(encodeURIComponent(destination));
  }

  return path.join("/");
}
