import type { LanguageCode } from "src/utils/language";
import { buildLandingPagePathname } from "./buildLandingPagePathname";
import type { Context } from "@marko/run";

export function buildPathnameFromRoute(
  route: Context["route"],
  languageCode: LanguageCode,
  params: Context["params"],
): string {
  // Homepage route
  if (route === "/" || route === "/:lang") {
    return languageCode === "en" ? "/" : `/${languageCode}/`;
  }

  // Landing pages: "/s/:origin/:destination?"
  const isDirectFlights =
    route.startsWith("/s/Direct-Flights") ||
    route.startsWith("/:lang/s/Direct-Flights");
  const isTransitNear =
    route.startsWith("/s/Transit-Near") ||
    route.startsWith("/:lang/s/Transit-Near");
  const isLandingPage = route.startsWith("/s") || route.startsWith("/:lang/s");
  if (isLandingPage && !isDirectFlights && !isTransitNear) {
    const tripParams = params as {
      origin: string;
      destination: string;
    };
    console.log("tripParams", tripParams);
    return buildLandingPagePathname(
      languageCode,
      tripParams.origin || "Nearby-Airports",
      tripParams.destination,
    );
  }

  // Generic replacement of route params
  const pathParams = { lang: languageCode, ...params };
  let pathName = route.startsWith("/:lang") ? route : `/:lang${route}`;

  for (const key in pathParams) {
    pathName = pathName.replace(
      `:${key}`,
      encodeURIComponent(pathParams[key as keyof typeof pathParams]),
    );
  }

  // Remove any starting /en/ since English is the default
  pathName = pathName.replace(/^\/en\//, "/");

  return pathName;
}
