import {
  type FeatureName,
  type FeatureConfig,
} from "src/feature/FeatureConfig";
import { getFeatureConfigFromSearchParams } from "src/feature/getFeatureConfigFromSearchParams";
import { getFeatureConfigString } from "src/feature/getFeatureConfigString";

export default class extends Marko.Component<unknown, unknown> {
  onCheckboxChange(event: Event, element: HTMLInputElement) {
    const url = new URL(window.location.href);
    const configFeature = getConfigFeature(url);
    Object.assign(configFeature, {
      [element.name as FeatureName]: element.checked,
    });
    redirectToConfigFeature(url, configFeature);
  }
  onSelectChange(event: Event, element: HTMLSelectElement) {
    const url = new URL(window.location.href);
    const configFeature = getConfigFeature(url);
    Object.assign(configFeature, {
      [element.name as FeatureName]: element.value,
    });
    redirectToConfigFeature(url, configFeature);
  }
}

function getConfigFeature(url: URL) {
  const featuresQueryParam =
    url.searchParams.get("features") ?? url.searchParams.get("feat");
  return featuresQueryParam
    ? getFeatureConfigFromSearchParams(featuresQueryParam)
    : {};
}

function redirectToConfigFeature(
  url: URL,
  configFeature: Partial<FeatureConfig>,
) {
  const newFeaturesQueryParam = getFeatureConfigString(configFeature);
  url.searchParams.set("features", newFeaturesQueryParam);
  url.search = decodeURIComponent(url.search);
  window.location.href = url.href;
}
