import type { TransitSubKind } from "src/api/common/TripResponse";

export function compressMode(kind?: TransitSubKind): Mode {
  if (!kind || !knownModes.includes(kind as KnownMode)) {
    return "na";
  } else {
    return kind as Mode;
  }
}

export type KnownMode = (typeof knownModes)[number];
type Mode = KnownMode | "na";

// This serializer needs to be kept in sync with c# backend and BigQuery deserialisation.
// For more information, see:
// * https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2354610204/ExitLabel+and+ExitPoint+encoding
// * https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio/Code/Store/ExitPointSerializer.cs#L398
const knownModes: TransitSubKind[] = [
  "train",
  "bus",
  "car",
  "taxi",
  "plane",
  "ferry",
  "shuttle",
  "towncar",
  "rideshare",
  "tram",
  "cablecar",
  "helicopter",
] as const;
