import type { Context } from "@marko/run";
import { setLanguage, type LanguageCode } from "src/utils/language";
import { buildPathnameFromRoute } from "src/url/buildPathnameFromRoute";
import { setCurrency } from "src/utils/currency";

export default class extends Marko.Component {
  handleCurrencyChanged(event: Event) {
    const target = event.target as HTMLSelectElement;
    const value = target.value as LanguageCode;
    setCurrency(value);

    window.location.reload();
  }

  handleLanguageChanged(
    route: Context["route"],
    params: Context["params"],
    event: Event,
  ) {
    const target = event.target as HTMLSelectElement;
    const value = target.value as LanguageCode;
    setLanguage(value);

    const newPath = buildPathnameFromRoute(route, value, params);
    window.location.replace(newPath);
  }
}
