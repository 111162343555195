import type { ExperimentConfig } from "./ExperimentConfig";
import {
  type ExperimentName,
  type ExperimentVariant,
  experimentDefinitions,
} from "./ExperimentDefinition";

export function getExperimentConfigFromSearchParams(
  queryParam: string,
): Partial<ExperimentConfig> {
  const experiments = queryParam.split(",");
  const experimentConfig: Partial<ExperimentConfig> = {};
  for (const experiment of experiments) {
    const [experimentName, experimentVariant] = experiment.split(":") as [
      ExperimentName,
      ExperimentVariant<ExperimentName>,
    ];
    if (Object.keys(experimentDefinitions).includes(experimentName)) {
      Object.assign(experimentConfig, {
        [experimentName]: experimentVariant,
      });
    }
  }

  return experimentConfig;
}
