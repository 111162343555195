import { setupPopunder } from "src/popunder/Popunder";
import type { Input } from "./index.marko";

export default class extends Marko.Component<Input, unknown> {
  onMount() {
    setupPopunder({
      originCanonical: this.input.originCanonical,
      destinationCanonical: this.input.destinationCanonical,
    });
  }
}
