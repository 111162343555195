// Maps experiments to their ids and variant definitions as defined in the backend.
// The order of the variants matters and should match the order on the backend.
//
// See:
// * https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Experiments/ExperimentName.cs:
// * https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Experiments/ExperimentDefinitions.cs
export const experimentDefinitions = {
  NearbyAirportsRewrite2023MVP: {
    experimentId: "cc",
    variants: [
      "Baseline",
      "Rewrite",
      "Baseline",
      "Rewrite",
      "Baseline",
      "Rewrite",
      "Baseline",
      "Rewrite",
      "Baseline",
      "Rewrite",
    ],
  },
  SnowplowRollout: {
    experimentId: "cb",
    variants: ["Baseline", "Snowplow"],
  },
  HotelsBcomPopundersForUsCaUsers: {
    experimentId: "el",
    variants: ["Baseline", "BcomPopunders"],
  },
  LPINPDiagnosis: {
    experimentId: "hl",
    variants: ["Baseline", "NoCMP", "NoAds", "NoDataSharing"],
  },
  ModeHeroBlockOptimisation: {
    experimentId: "hx",
    variants: [
      "Baseline",
      "Layout",
      "BestMode",
      "ToDFromO",
      "Multiple",
      "Remove",
    ],
  },
  OperatorA2B: {
    experimentId: "hz",
    variants: ["Baseline", "Title", "RouteOptions", "HeroBlock", "Headings"],
  },
} as const satisfies ExperimentDefinitions;

type ExperimentDefinitions = Record<
  string,
  { experimentId: string; variants: string[] }
>;

export type ExperimentName = keyof typeof experimentDefinitions;

export type ExperimentVariant<T extends ExperimentName> =
  | (typeof experimentDefinitions)[T]["variants"][number]
  | "None";

// Given an experiment id it returns the human-readable string name for it or
// undefined if the experiment hasn't been added to experimentDefinitions above.
export function getExperimentNameFromId(
  experimentId: string,
): ExperimentName | undefined {
  return experimentIdMap[experimentId];
}

// Given an experiment name and variant id it returns the human-readable string
// name for it or undefined if the experiment hasn't been added to
// `experimentDefinitions` above.
export function getExperimentVariantFromExperiment<T extends ExperimentName>(
  experiment: T,
  variantId: number,
): ExperimentVariant<T> {
  const variantDefinition = experimentDefinitions[experiment]?.variants;
  if (!variantDefinition) {
    return "None";
  }

  if (variantId === 0) {
    return "None";
  } else {
    return variantDefinition[variantId - 1];
  }
}

// Create a map of Experiment ids -> Experiment names once so we can efficiently
// get the ExperimentName for each id.
const experimentNames = Object.keys(experimentDefinitions) as ExperimentName[];
const experimentIdMap: Partial<Record<string, ExperimentName>> =
  experimentNames.reduce((previousValue, currentValue) => {
    const experimentId = experimentDefinitions[currentValue].experimentId;
    return {
      ...previousValue,
      [experimentId]: currentValue,
    };
  }, {});
export const experimentIds = Object.keys(experimentIdMap);
