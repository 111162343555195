import { sendEvent } from "../../../analytics/sendEvent";
import { hasOptedOutOfAds } from "../../cookie-consent/optOutOfAds";

<lifecycle
  onMount() {
    sendEvent({
      category: "Ads",
      action: "Init:gpt",
      label: "queuing",
      isNonInteraction: true,
    });
    window.googletag.cmd.push(() => {
      sendEvent({
        category: "Ads",
        action: "Init:gpt",
        label: "started",
        isNonInteraction: true,
      });

      if (hasOptedOutOfAds()) {
        // setRequestNonPersonalizeAds doesn't exist in the TypeScript definition,
        // but is documented as existing here:
        // * https://support.google.com/adsense/answer/7670312?hl=en
        //
        // Also it was added in the GPT release notes:
        // * https://developers.google.com/publisher-tag/release-notes
        // @ts-ignore
        window.googletag.pubads().setRequestNonPersonalizedAds(1);
      }

      window.googletag.pubads().enableLazyLoad({
        // These settings must balance between fetching ads early enough that they load
        // before the user scrolls to them, and fetching them only when they're likely to
        // be viewed.
        fetchMarginPercent: 50,
        renderMarginPercent: 20,
        mobileScaling: 2.0,
      });

      window.googletag.enableServices();
      sendEvent({
        category: "Ads",
        action: "Init:gpt",
        label: "complete",
        isNonInteraction: true,
      });
    });
  }
/>
