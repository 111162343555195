import Cookies from "js-cookie";
import { getCookie } from "src/utils/getCookie";

// The following names and values matches with the existing landing pages.
const OPT_OUT_OF_ADS_COOKIE_NAME = "disable_personalised_ads";
const OPT_OUT_OF_ADS_TRUE_VALUE = "1";
/**
 * @returns whether or not the user has opt for being out of ads.
 */
export function hasOptedOutOfAds(request?: Request): boolean {
  // If the user doesn't have the OPT_OUT_OF_ADS_COOKIE_NAME cookie as OPT_OUT_OF_ADS_TRUE_VALUE,
  // they have not clicked yet, so we can say it is false.
  const optOutOfAdsCookie = getCookie(OPT_OUT_OF_ADS_COOKIE_NAME, request);
  return optOutOfAdsCookie === OPT_OUT_OF_ADS_TRUE_VALUE;
}

export function setOptOutOfAds() {
  const tenYearsInDays = 10 * 365;
  Cookies.set(OPT_OUT_OF_ADS_COOKIE_NAME, OPT_OUT_OF_ADS_TRUE_VALUE, {
    expires: tenYearsInDays,
  });
}
