let isThrottling = false;

// Throttle a callback function based on the time provided in ms.
export function throttle(time: number, callbackFn: () => void) {
  // Only implement callback function if the throttling timeout is not already running.
  if (!isThrottling) {
    isThrottling = true;

    setTimeout(() => {
      callbackFn();
      isThrottling = false;
    }, time);
  }
}
