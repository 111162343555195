import type { ExperimentConfig } from "src/experiment/ExperimentConfig";
import type { ExperimentName } from "src/experiment/ExperimentDefinition";
import { getExperimentConfigFromSearchParams } from "src/experiment/getExperimentConfigFromSearchParams";
import { getExperimentConfigString } from "src/experiment/getExperimentConfigString";
import type { Input } from "./experiments-list.marko";

export default class extends Marko.Component<Input, unknown> {
  onSelectChange(event: Event, element: HTMLSelectElement) {
    const url = new URL(window.location.href);
    const experimentsConfig = getExperimentsConfig(url);
    Object.assign(experimentsConfig, {
      [element.name as ExperimentName]: element.value,
    });
    redirectToExperiment(url, experimentsConfig);
  }
}

function getExperimentsConfig(url: URL) {
  const queryParam = "exp";
  const experimentQueryParam = url.searchParams.get(queryParam);
  return experimentQueryParam
    ? getExperimentConfigFromSearchParams(experimentQueryParam)
    : {};
}

function redirectToExperiment(
  url: URL,
  experimentConfig: Partial<ExperimentConfig>,
) {
  const newExperimentsQueryParam = getExperimentConfigString(experimentConfig);
  const queryParam = "exp";
  url.searchParams.set(queryParam, newExperimentsQueryParam);
  url.search = decodeURIComponent(url.search);
  window.location.href = url.href;
}
