export default class extends Marko.Component<unknown, unknown> {
  onCheckboxChange(event: Event, element: HTMLInputElement) {
    const currentParams = new URLSearchParams(window.location.search);
    let currentBackendFeatures = currentParams.get("backendFeatures");

    if (currentBackendFeatures) {
      currentBackendFeatures +=
        "," + (element.checked ? "" : "!") + element.name;
    } else {
      currentBackendFeatures = (element.checked ? "" : "!") + element.name;
    }

    currentParams.set("backendFeatures", currentBackendFeatures);
    window.location.search = currentParams.toString();
  }
}
