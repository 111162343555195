import type { Trigger } from "src/analytics/generateExitPoint/compressTrigger";
import { generateExitPoint } from "src/analytics/generateExitPoint/generateExitPoint";
import type { LanguageCode } from "src/utils/language";

export function buildHotelsRedirectUrl(options: {
  view: string;
  trigger: Trigger;
  requestId?: string;
  originCanonicalName?: string;
  destinationCanonicalName: string;
  hotelProvider?: string;
  languageCode: LanguageCode;
  navigationEventId: string;
}) {
  const builder = new URL("https://www.rome2rio.com/redirects/hotels");
  builder.searchParams.append(
    "label",
    generateExitPoint(options.view, options.trigger, "Popunder"),
  );
  if (options.requestId) {
    builder.searchParams.append("requestId", options.requestId);
  }
  builder.searchParams.append("location", options.destinationCanonicalName);
  if (options.originCanonicalName) {
    builder.searchParams.append("origin", options.originCanonicalName);
  }
  if (options.hotelProvider) {
    builder.searchParams.append("provider", options.hotelProvider);
  }
  builder.searchParams.append("language", options.languageCode);
  builder.searchParams.append("navigationEventId", options.navigationEventId);
  return builder.href;
}
