import { initializeTcDataAnalyticsLogging } from "@rome2rio/tcf-api";
import { sendEvent } from "../../../analytics/sendEvent";
import { DetectAdBlock } from "../../display-ad/DetectAdBlock";

<lifecycle
  onMount() {
    initializeTcDataAnalyticsLogging(
      (category: string, action: string, label?: string, value?: number) =>
        sendEvent({ category, action, label, value, isNonInteraction: true }),
    );

    if (!$global.backendFeatureConfig.NoAdsOnLPs) {
      DetectAdBlock.initialize();
    }

    if ($global.backendFeatureConfig.HeaderBiddingLP) {
      // Media.net related events,
      // seperate from Media.NET currently due to it being in a <script> tag
      window.googlefc.callbackQueue.push({
        CONSENT_API_READY: () => {
          sendEvent({
            category: "Ads",
            action: "Init:MediaNet",
            label: "queuing",
            isNonInteraction: true,
          });
        },
      });

      window.mnjs.que.push(() => {
        sendEvent({
          category: "Ads",
          action: "Init:MediaNet",
          label: "complete",
          isNonInteraction: true,
        });
      });
    }
  }
/>
