import Cookies from "js-cookie";

const CURRENCY_COOKIE_NAME = "currency";

export function setCurrency(currencyCode: string) {
  const tenYearsInDays = 10 * 365;
  Cookies.set(CURRENCY_COOKIE_NAME, currencyCode, {
    expires: tenYearsInDays,
  });
}
