import { FeatureConfigValues } from "src/feature/FeatureConfig";
import Cookies from "js-cookie";

// Languages are defined in the feature config, so we derive
// our supported languages and language codes from those values.
export const supportedLanguages = FeatureConfigValues.LanguageCode;
export type LanguageCode = (typeof supportedLanguages)[number];

const LANGUAGE_COOKIE_NAME = "language";

export function setLanguage(languageCode: LanguageCode) {
  const tenYearsInDays = 10 * 365;
  Cookies.set(LANGUAGE_COOKIE_NAME, languageCode, {
    expires: tenYearsInDays,
  });
}

export const languages = {
  en: "English",
  cs: "čeština",
  da: "Dansk",
  de: "Deutsch",
  el: "Ελληνικά",
  es: "Español",
  fi: "Suomen kieli",
  fr: "Français",
  hr: "Hrvatski",
  hu: "Magyar",
  ko: "한글",
  ms: "Bahasa Melayu",
  it: "Italiano",
  nl: "Nederlands",
  ja: "日本語",
  no: "Norsk",
  pl: "Polski",
  pt: "Português",
  ro: "Românește",
  ru: "Русский",
  sk: "Slovenčina",
  sv: "Svenska",
  th: "ไทย",
  zh: "中文简体",
} as const satisfies Record<LanguageCode, string>;

export function isLanguageCode(value: string): value is LanguageCode {
  return supportedLanguages.includes(value as LanguageCode);
}

export function getHtmlLanguageCodeFromLanguageCode(
  languageCode: LanguageCode,
): string {
  if (languageCode === "zh") {
    return "zh-Hans";
  }
  return languageCode;
}

export const getHelpLanguageCode = (code: LanguageCode) => {
  const language = {
    en: "en",
    de: "de",
    es: "es",
    pt: "pt-BR",
    it: "it",
    fr: "fr",
    ru: "ru-RU",
    pl: "pl",
    nl: "nl",
    sv: "sv",
    no: "no",
    da: "da",
    ja: "ja",
    ko: "ko",
    th: "th",
    zh: "zh",
    ro: "ro",
    ms: "ms",
    cs: "cs",
    hu: "hu",
    el: "el",
    fi: "fi",
    sk: "sk",
    hr: "hr",
  };

  return language[code] ?? "en";
};
