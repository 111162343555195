import type { FeatureFilterFunc } from "./FeatureFilter";
import type { Context } from "@marko/run";

/**
 * Uses the User-Agent of the request to determine whether the feature should
 * be enabled for a specific user or not.
 */
export function isMobile(): FeatureFilterFunc {
  return (context: Context) => {
    // If the  browser supports the `Sec-CH-UA-Mobile` header, use that because
    // it's more reliable than the User-Agent sniffing.
    //
    // Only Chromium-based browsers supports it though.
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-CH-UA-Mobile
    const clientMobileHint = context.request.headers.get("Sec-CH-UA-Mobile");
    if (clientMobileHint) {
      return clientMobileHint === "?1" ? true : undefined;
    }

    // Fallback on User-Agent sniffing.
    const userAgent = context.request.headers.get("user-agent");
    const isMobile = !!userAgent && isMobileUserAgent(userAgent);
    return isMobile ? true : undefined;
  };
}

// This was copied from UserAgentHelper.cs in rome2rio-core.
// See: https://github.com/rome2rio/rome2rio-core/blob/3071cab8c0a8e0d17984581b23f5124089928b7b/rome2rio/Code/Common/UserAgentHelper.cs#L115
function isMobileUserAgent(userAgent: string): boolean {
  return [
    "iphone",
    "ipod",
    "android",
    "blackberry",
    "mini",
    "windows ce",
    "palm",
  ].some((deviceName) => userAgent.toLowerCase().includes(deviceName));
}
