import fallbackImg from "./fallback-image.png";
import type { FeatureConfig } from "src/feature/FeatureConfig";
class {
  declare state: {
    forceFallbackImages: any;
  };
  onCreate(input: {}, out: Marko.Out) {
    this.state = {
      forceFallbackImages: (out.global.featureConfig as FeatureConfig)
        .ForceFallbackImages,
    };
  }
  onMount() {
    // If an image src is broken, then we want to fallback to a simple grey background so that the user
    // isn't presented with the browser's ugly default broken image icon.
    document.querySelectorAll("img").forEach((img) => {
      if (this.state.forceFallbackImages) {
        img.src = "";
      }

      img.addEventListener(
        "error",
        (event) => {
          const imgSrc = (event.target as HTMLImageElement)?.src;
          const fallback = img.dataset.fallbackSrc ?? fallbackImg;

          img.src = fallback;

          // Don't log an error to Sentry if we are forcing fallback images.
          if (!imgSrc || this.state.forceFallbackImages) return;
          if (import.meta.env.DEV) {
            console.error(`Failed to load image: ${imgSrc}`);
          }
        },
        { once: true },
      );
    });
  }
}
