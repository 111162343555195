import type { Input } from "./track-page-scroll.marko";
import { throttle } from "./throttle";
import { sendEvent } from "src/analytics/sendEvent";
import type { Meta } from "@marko/run";

export interface State {
  currentScrollPercent: number;
  eventCategory: string;
}

export default class extends Marko.Component<Input, State> {
  onCreate(input: Input, out: Marko.Out) {
    this.state = {
      currentScrollPercent: 0,
      eventCategory: (out.global.meta as Meta).analytics.page,
    };
  }

  onMount() {
    this.subscribeTo(window).on("scroll", () =>
      throttle(300, this.handleScroll.bind(this)),
    );
  }

  handleScroll() {
    const bodyEl = document.body;
    const scrollEl = document.scrollingElement;

    if (!(bodyEl && scrollEl)) {
      return null;
    }

    const scrollPos = scrollEl.scrollTop;
    const bodyHeight = bodyEl.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrollPercent = scrollPos / (bodyHeight - windowHeight);
    const scrollPercentRounded = Math.round((scrollPercent * 100) / 10) * 10;

    if (scrollPercentRounded > this.state.currentScrollPercent) {
      this.state.currentScrollPercent = scrollPercentRounded;

      sendEvent({
        category: this.state.eventCategory,
        action: "ScrolledPercent",
        label: scrollPercentRounded.toString(),
        isNonInteraction: true,
      });
    }
  }
}
