import type { Trigger } from "src/analytics/generateExitPoint/compressTrigger";
import type { TransitSubKind } from "src/api/common/TripResponse";
import { sendEvent } from "src/analytics/sendEvent";
import { navigateToExplore } from "src/popunder/Popunder";
import type { Method } from "src/analytics/generateExitPoint/compressMethod";
import { generateExitPoint } from "src/analytics/generateExitPoint/generateExitPoint";
import { generateNavigationEventId } from "src/analytics/generateNavigationEventId/generateNavigationEventId";
import { AnalyticsApi } from "src/api/AnalyticsApi/AnalyticsApi";
import type { ExtraInfoProps } from "src/analytics/snowplow/snowplowEntities";
export interface Input extends Marko.Input<"a"> {
  // Override the a tags href attribute because exit links must have one.
  href: string;
  view: string;
  trigger: Trigger;
  method: Method;
  provider?: string;
  agencyIds?: string[];
  mode?: TransitSubKind;
  originCanonicalName?: string;
  destinationCanonicalName?: string;
}

<const/{
  view,
  trigger,
  method,
  mode,
  provider,
  agencyIds,
  originCanonicalName,
  destinationCanonicalName,
  ...anchorRest
}=input />

<const/exitPoint=generateExitPoint(view, trigger, method, mode) />

<!-- We're a redirect if the URL is `/redirects/**` or `.rome2rio.com/redirects/**`. -->
static const REDIRECTS_REGEX = /(^|\.rome2rio\.com)\/redirects\//;

<const/logExitEvent(exitHref: string, extraInfo?: ExtraInfoProps) {
  // TODO: This event is not inline with the Explore exit event schema. We should make it the same.
  // See: https://github.com/rome2rio/rome2rio-explore/blob/ea22104bd3c7bd0aec5f48dac2bf56e36e727614/src/analytics/sendAnalyticsExitEvent.ts#L12
  sendEvent({
    category: view,
    action: `Exit:${exitPoint}`,
    isNonInteraction: true,
    extraInfo: extraInfo,
  });
  if (!REDIRECTS_REGEX.test(exitHref)) {
    // If the exit is not through one of our redirects then we need to manually log the exit
    // to the backend.
    AnalyticsApi.logExit(
      {
        category: view,
        action: `Exit:${exitPoint}`,
        currentUrl: window.location.href,
        destinationUrl: exitHref,
        provider: provider,
        agencyIds: agencyIds,
        rawExitPoint: exitPoint,
      },
      {
        uid: $global.r2rUserContext.uid,
        aqid: $global.r2rUserContext.aqid,
        requestId: $global.r2rUserContext.requestId,
      },
    );
  }
} />

<if=(method === "Popunder")>
  <const/defaultProvider=$global.popunderProvider />
  <const/isRateLimitEnabled=(
    $global.backendFeatureConfig.PopunderRateLimit ?? false
  ) />
  <const/languageCode=$global.languageCode />
  <const/requestId=($global.r2rUserContext?.requestId) />

  <a
    ...anchorRest
    onClick(event) {
      const eventId = generateNavigationEventId();
      const extraInfo = {
        dimensions: { navigationEventId: eventId },
      };
      const [didAttemptPopunder, exitHref] = navigateToExplore(input.href, {
        view,
        trigger,
        originCanonicalName,
        destinationCanonicalName,
        popunderProvider: provider ?? defaultProvider,
        languageCode,
        requestId,
        isRateLimitEnabled,
        eventId,
      });

      if (didAttemptPopunder) {
        logExitEvent(exitHref, extraInfo);
        // Stop the event from triggering the anchor tag because we're handling it with the popunder.
        event.preventDefault();
      }
    }
  >
    <${input.renderBody} />
  </a>
</if>
<else-if=(method === "PopOut")>
  <a
    ...anchorRest
    target="_blank"
    rel="noopener nofollow"
    onClick() {
      logExitEvent(input.href);
    }
  >
    <${input.renderBody} />
  </a>
</else-if>
<else>
  <a
    ...anchorRest
    onClick() {
      logExitEvent(input.href);
    }
  >
    <${input.renderBody} />
  </a>
</else>
