import {
  type FeatureConfig,
  type FeatureName,
  type FeatureValue,
  FeatureConfigValues,
} from "./FeatureConfig";

/**
 * Parses FeatureConfig object in 'feature' query param format:
 * FeatureConfig object
 * into LogGaEventsToConsole,!SkipRoutes,FillAds:default string.
 * @param featureParams FeatureConfig object with the parsed features.
 * @returns A query param string format.
 */
export function getFeatureConfigString(
  featureConfig: Partial<FeatureConfig>,
): string {
  const features: string[] = [];

  for (const [featureName, featureValue] of Object.entries(featureConfig)) {
    const possibleValues = FeatureConfigValues[featureName as FeatureName];

    // Check if the feature is a string array feature.
    if (isBooleanFeature(possibleValues)) {
      if (featureValue) {
        features.push(featureName);
      } else {
        features.push(`!${featureName}`);
      }
    } else {
      features.push(`${featureName}:${featureValue}`);
    }
  }

  return features.join(",");
}

function isBooleanFeature(
  possibleValues: readonly FeatureValue<FeatureName>[],
) {
  return (
    possibleValues.includes(true) &&
    possibleValues.includes(false) &&
    possibleValues.length === 2
  );
}
