import type { IntlShape } from "@formatjs/intl";

export interface Input {
  message: Parameters<IntlShape["formatMessage"]>[0];
  values?: Parameters<IntlShape["formatMessage"]>[1];
  options?: Parameters<IntlShape["formatMessage"]>[2];
  unescaped?: boolean;
}

<if(input.unescaped)>
  $!{$global.intl?.formatMessage(input.message, input.values, input.options)}
</if>
<else>
  ${$global.intl?.formatMessage(input.message, input.values, input.options)}
</else>
