import { sendEvent } from "src/analytics/sendEvent";

declare global {
  namespace Marko {
    interface HTMLAttributes {
      /**
       * The category of the event logged to analytics. This is required if you set data-log="on-click".
       */
      "data-category"?: string;
      /**
       * The action of the event logged to analytics. This is required if you set data-log="on-click".
       */
      "data-action"?: string;
      /**
       * The label of the event logged to analytics.
       */
      "data-label"?: string;
      /**
       * If you set data-log="on-click" you must also set the data-category and data-action attributes.
       */
      "data-log"?: "on-click";
    }
  }
}

export default class extends Marko.Component<{}, {}> {
  onMount() {
    // Subscribe to all click events on the document and check if the target is a tracked element.
    // We don't subscribe to each element individually because we want to avoid the overhead of
    // creating a new event listener for each element. It also makes it easier to handle dynamically
    // added elements or re-rendered elements.
    this.subscribeTo(document).on("click", (event) => {
      const trackedElement = getTrackedElement(event.target);

      if (trackedElement) {
        trackInteractionWith(trackedElement);
      }
    });

    if (import.meta.env.DEV) {
      // On localhost we want to throw errors if the data attributes are missing because
      // TypeScript cannot optionally enforce attributes on the HTML elements.
      document.querySelectorAll('[data-log="on-click"]').forEach((element) => {
        if (!getTrackedElement(element)) {
          console.error(
            "Element registered to be tracked but does not provide required data attributes",
            element,
          );
          throw Error(
            "Element registered to be tracked but does not provide required data attributes",
          );
        }
      });
    }
  }
}

function getTrackedElement(element: Element): HTMLElement | null {
  if (isElementTracked(element)) {
    return element;
  }

  const trackedParentElement = element.closest<HTMLElement>(
    '[data-log="on-click"]',
  );
  if (trackedParentElement && isElementTracked(trackedParentElement)) {
    return trackedParentElement;
  }

  return null;
}

function isElementTracked(element: Element): element is HTMLElement {
  return !!(
    element instanceof HTMLElement &&
    element.dataset["log"] === "on-click" &&
    element.dataset["category"] &&
    element.dataset["action"]
  );
}

function trackInteractionWith(element: HTMLElement) {
  if (element.dataset["log"] != "on-click") {
    console.error("Element does not have data-log attribute");
    return;
  }

  const category = element.dataset["category"];
  const action = element.dataset["action"];
  const label = element.dataset["label"];

  if (!category) {
    if (import.meta.env.DEV) {
      console.error("Element does not have data-category attribute");
    } else {
      window.Sentry?.captureMessage(
        "Element does not have data-category attribute",
        "warning",
      );
    }
    return;
  }

  if (!action) {
    if (import.meta.env.DEV) {
      console.error("Element does not have data-action attribute");
    } else {
      window.Sentry?.captureMessage(
        "Element does not have data-action attribute",
        "warning",
      );
    }
    return;
  }

  sendEvent({
    category: category,
    action: action,
    ...(label && { label: label }),
  });
}
