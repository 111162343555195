export function compressTrigger(trigger: Trigger): CompressedTrigger {
  return triggers[trigger] ?? triggers.Unknown;
}

export type Trigger = keyof typeof triggers;
export type CompressedTrigger = (typeof triggers)[Trigger];

// This serializer needs to be kept in sync with c# backend and BigQuery deserialisation.
// For more information, see:
// https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2354610204/ExitLabel+and+ExitPoint+encoding
const triggers = {
  Unknown: "u",
  TransportSearch: "trs",
  TicketSearch: "ts",
  HotelSearch: "hs",
  TicketCta: "tcta",
  HotelCta: "hcta",
  HotelAd: "had",
  RentalCarCTA: "ccta",
  LandingPageQuestionCTA: "qcta",
  LandingPageMapCTA: "mcta",
  RouteLink: "rl",
  RouteDetailsCTA: "rdcta",
  RouteTicketsCTA: "rtcta",
  Operator: "olnk",
  AttractionCTA: "acta",
  DestBlock: "deb",
  DestTile: "det",
  OperatorBlockAgencyWebsite: "opaw",
  OperatorBlockActionLink: "opal",
  OperatorBlockTransitImage: "opti",
  // "HeroSummaryExploreCta" is used in legacy, but it does not seem to have a requisite exit trigger.
  // see: https://github.com/rome2rio/rome2rio-core/blob/ab5e6af3334b259db4bcdf631eb92957929dc3d0/rome2rio/Code/Store/ExitPointSerializer.cs#L367
  HeroSummaryExploreCta: "hscta",
} as const;

export const triggerKeys = Object.keys(triggers) as Trigger[];
