import { messages } from "../r2r-footer.messages";
import { getConsentImplementation } from "@rome2rio/tcf-api";

class {
  declare state: { showConsentMessage: boolean };

  onCreate() {
    this.state = { showConsentMessage: false };
  }
  onMount() {
    getConsentImplementation()
      .then((consentImplementation) => {
        this.state.showConsentMessage = consentImplementation.includes("gdpr");
      })
      .catch((error) => {
        window.Sentry?.captureException(
          error || new Error("Unknown error in getConsentImplementation"),
        );
      });
  }
}
/*
 * To ensure we don't re-render formatted-message,
 * we use no-update-body and instead hide the element when the consent message is not shown
 */
<li class="inline-block font-light leading-5 md:w-full">
  <a
    class="border-b border-b-neutral-600 text-sm font-light hover:border-b-rome2rio-pink"
    data-log="on-click"
    data-category="FooterClicked"
    data-action="Click:ManageConsent"
    href="javascript:googlefc.callbackQueue.push(googlefc.showRevocationMessage)"
    hidden=!state.showConsentMessage
    no-update-body
  >
    <formatted-message message=messages.manageConsentLink />
  </a>
</li>
