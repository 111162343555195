import type { FeatureFilterFunc } from "./FeatureFilter";
import type { Context } from "@marko/run";
import { isMobile } from "./isMobile";

/**
 * Uses the User-Agent of the request to determine whether the feature should
 * be enabled for a specific user or not.
 */
export function isDesktop(): FeatureFilterFunc {
  const isMobileFilter = isMobile();
  return (context: Context) => {
    const result = isMobileFilter(context);
    if (result === undefined) {
      return true;
    } else {
      return undefined;
    }
  };
}
