import { registerOnConsent } from "@rome2rio/tcf-api";
export interface Input {
  consentPurposesRequired: number[];
  legitimateInterestPurposesRequired: number[];
}
static type ConsentStatus = "unknown" | "given" | "revoked";
<let<ConsentStatus>/status="unknown" />

<const/consentGrantedCallback() {
  status = "given";
} />
<const/consentRevokedCallback() {
  status = "revoked";
} />
<effect() {
  if ($global.featureConfig.MockConsentStatus !== "undefined") {
    if ($global.featureConfig.MockConsentStatus === "given") {
      consentGrantedCallback();
    } else {
      consentRevokedCallback();
    }
    return () => {};
  }
  const unsubscribe = registerOnConsent({
    onGiven: consentGrantedCallback,
    onRevoked: consentRevokedCallback,
    gdpr: {
      consentPurposesRequired: input.consentPurposesRequired,
      legitimateInterestPurposesRequired:
        input.legitimateInterestPurposesRequired,
    },
    us: {
      saleConsentRequired: true,
      sharingConsentRequired: true,
    },
  });
  return unsubscribe;
} />
<return=status />
