export function compressMethod(value: Method): CompressedMethod {
  return method[value];
}

export type Method = keyof typeof method;
export type CompressedMethod = (typeof method)[Method];

// This serializer needs to be kept in sync with c# backend and BigQuery deserialisation.
// For more information, see:
// https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2354610204/ExitLabel+and+ExitPoint+encoding
const method = {
  Popunder: "pu",
  ExitOverlay: "eo",
  PopOut: "po",
  Navigate: "nv",
  Unknown: "u",
} as const;

export const methodKeys = Object.keys(method) as Method[];
