import { initializeTcDataAnalyticsLogging } from "@rome2rio/tcf-api";
import { sendEvent } from "../../analytics/sendEvent";
import { DetectAdBlock } from "../display-ad/DetectAdBlock";
import { hasOptedOutOfAds } from "../cookie-consent/optOutOfAds";
<!--
  Preload the GPT script so that it starts loading as soon as possible. We manually append the script tag
  to the DOM to ensure that we catch load errors.
 -->
<link
  rel="preload"
  href="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
  as="script"
  fetchpriority="low"
/>

<!-- Make sure that googletag.cmd exists as it may not have loaded yet. -->
<${`script`}>
  window.googletag = window.googletag || {};
  // @ts-ignore
  window.googletag.cmd = window.googletag.cmd || []; window.googlefc = window.googlefc
  || {}; window.googlefc.ccpa = window.googlefc.ccpa || {};
  // @ts-ignore
  window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];
  // Signals that the default Do Not Sell link will be overridden.
  // https://developers.google.com/funding-choices/fc-api-docs#using-funding-gpp-cpra-custom-dns
  window.googlefc.ccpa.overrideDnsLink = true; window.mnjs = window.mnjs || {}; window.mnjs.que
  = window.mnjs.que || [];
</>

<lifecycle
  onMount() {
    window.googlefc = window.googlefc || {};
    // @ts-ignore
    window.googlefc.callbackQueue = window.googlefc.callbackQueue || [];

    let gptScript = document.createElement("script");
    gptScript.setAttribute("async", "async");
    gptScript.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

    gptScript.onerror = () => DetectAdBlock.onGptLoadFailed();

    document.head.append(gptScript);

    window.googletag = window.googletag || {};
    // When the GPT JavaScript is loaded, it looks through the cmd array and executes all the functions in order.
    // @ts-ignore
    window.googletag.cmd = window.googletag.cmd || [];

    if (!$global.backendFeatureConfig.NoAdsOnLPs) {
      DetectAdBlock.initialize();
    }

    initializeTcDataAnalyticsLogging(
      (category: string, action: string, label?: string, value?: number) =>
        sendEvent({ category, action, label, value, isNonInteraction: true }),
    );

    if ($global.backendFeatureConfig.NoAdsOnLPs) {
      return;
    }
    sendEvent({
      category: "Ads",
      action: "Init:gpt",
      label: "queuing",
      isNonInteraction: true,
    });
    window.googletag.cmd.push(() => {
      sendEvent({
        category: "Ads",
        action: "Init:gpt",
        label: "started",
        isNonInteraction: true,
      });

      if (hasOptedOutOfAds()) {
        // setRequestNonPersonalizeAds doesn't exist in the TypeScript definition,
        // but is documented as existing here:
        // * https://support.google.com/adsense/answer/7670312?hl=en
        //
        // Also it was added in the GPT release notes:
        // * https://developers.google.com/publisher-tag/release-notes
        // @ts-ignore
        window.googletag.pubads().setRequestNonPersonalizedAds(1);
      }

      window.googletag.pubads().enableLazyLoad({
        // These settings must balance between fetching ads early enough that they load
        // before the user scrolls to them, and fetching them only when they're likely to
        // be viewed.
        fetchMarginPercent: 50,
        renderMarginPercent: 20,
        mobileScaling: 2.0,
      });

      window.googletag.enableServices();
      sendEvent({
        category: "Ads",
        action: "Init:gpt",
        label: "complete",
        isNonInteraction: true,
      });
    });
  }
/>
// Queue the callback using the CONSENT_DATA_READY key on the callbackQueue.
// This is required for correct functioning of prebid.js (ref link: https://shorturl.at/cyzMY)
<if=($global.backendFeatureConfig.HeaderBiddingLP)>
  <!--
Preload the media.net header bidding scripts.
Needed here as we're delaying the actual load until
the CMP has gathered appropriate consent.
NOTE: the href MUST match that used in the main load script later in this file.
-->

  <const/mediaNetSrc=`https://warp.media.net/js/tags/clientag.js?cid=8CUB7YB0J&dn=${$global.url.hostname}&version=1`
  />

  <link as="script" rel="preload" href=mediaNetSrc />
  <const/mediaNetConfig={
    customerId: "8CUB7YB0J",
    domain: $global.url.hostname,
    failSafeTimeout: 3e3,
    version: 1.0,
    isAdServerLoaded: false,
  } />
  <lifecycle
    onMount() {
      function loadScript(tagSrc: string) {
        let scriptTag = document.createElement("script"),
          placeTag = document.getElementsByTagName("script")[0];
        scriptTag.type = "text/javascript";
        scriptTag.async = true;
        scriptTag.src = tagSrc;
        placeTag.parentNode?.insertBefore(scriptTag, placeTag);
      }

      // Loading media net library instead of gpt.js for header bidding
      window.googlefc.callbackQueue.push({
        CONSENT_DATA_READY: () => {
          // Load media.net code
          window.mnjs = Object.assign(window.mnjs, mediaNetConfig);

          window.mnjs.loadAdServer = setTimeout(function () {
            if (!window.mnjs.isAdServerLoaded) {
              loadScript(
                "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
              );
              window.mnjs.isAdServerLoaded = true;
            }
          }, window.mnjs.failSafeTimeout);

          loadScript(mediaNetSrc);
        },
      });

      window.googlefc.callbackQueue.push({
        CONSENT_DATA_READY: () => {
          sendEvent({
            category: "Ads",
            action: "Init:MediaNet",
            label: "queuing",
            isNonInteraction: true,
          });
        },
      });

      window.mnjs.que.push(() => {
        sendEvent({
          category: "Ads",
          action: "Init:MediaNet",
          label: "complete",
          isNonInteraction: true,
        });
      });
    }
  />
</if>
