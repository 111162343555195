export interface Input {
  consentPurposesRequired: number[];
  legitimateInterestPurposesRequired: number[];
  renderBody: Marko.Body;
}

<consent-status/status
  consentPurposesRequired=input.consentPurposesRequired
  legitimateInterestPurposesRequired=input.legitimateInterestPurposesRequired
/>

<if=(status === "given")><${input.renderBody} /></if>
