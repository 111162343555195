import type { TransitSubKind } from "src/api/common/TripResponse";
import type { Method } from "./compressMethod";
import { compressMethod } from "./compressMethod";
import { compressMode } from "./compressMode";
import type { Trigger } from "./compressTrigger";
import { compressTrigger } from "./compressTrigger";
import { compressView } from "./compressView";

// Label used to track where users exit from when they go to hotels, car hire, etc
// For more information, see:
// https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2354610204/ExitLabel+and+ExitPoint+encoding
export function generateExitPoint(
  view: string,
  trigger: Trigger,
  method: Method,
  transitSubKind?: TransitSubKind,
) {
  const points = ["fe1"];

  points.push(compressView(view));
  points.push(compressTrigger(trigger));
  points.push(compressMethod(method));
  points.push(compressMode(transitSubKind));

  return points.join(":");
}
