import {
  type FeatureConfig,
  FeatureConfigValues,
  type FeatureName,
  type FeatureValue,
} from "./FeatureConfig";

/**
 * Parses the 'feature' query param in the following format:
 * ?features=LogGaEventsToConsole,!SkipRoutes,FillAds:default
 * into a FeatureConfig object.
 * @param featureParams The query param string to parse for features.
 * @returns A FeatureConfig object with the parsed features.
 */
export function getFeatureConfigFromSearchParams(
  featureParams: string,
): Partial<FeatureConfig> {
  const features: Partial<FeatureConfig> = {};

  // Split different features.
  const searchParamsFeatures = featureParams.split(",");

  for (const searchParamFeature of searchParamsFeatures) {
    const splitFeature = searchParamFeature.split(":");

    let [featureName, maybeFeatureValue] = [
      splitFeature[0],
      // If value has a ':' in it, this will reassemble the proper value string
      splitFeature.splice(1).join(":"),
    ];

    // Boolean features are set using `!FeatureName` or `FeatureName`.
    const negator = featureName.startsWith("!");
    if (negator) featureName = featureName.substring(1);

    // Check valid feature name
    if (assertFeatureName(featureName)) {
      const possibleValues = FeatureConfigValues[featureName];

      // Check if the feature is a string array feature.
      if (isBooleanFeature(possibleValues)) {
        // We have to cast this, but we know setting a boolean is valid.
        (features as any)[featureName] = !negator;
      } else {
        const possibleFeatureValues = FeatureConfigValues[featureName];

        // Empty strings are 'undefined' for now
        const featureValue =
          maybeFeatureValue === "" ? undefined : maybeFeatureValue;

        if (possibleFeatureValues.findIndex((v) => v === featureValue) !== -1) {
          // We have to cast this as typescript can't narrow the type based on the stringArrayFeatures object.
          // We know it's valid because we've checked the value is in the array.
          (features as any)[featureName] = featureValue;
        }
        // Silently fail if the feature value is non-existent.
      }
    }
  }

  return features;
}

export function assertFeatureName(
  maybeFeatureName: string,
): maybeFeatureName is FeatureName {
  return Object.keys(FeatureConfigValues).includes(maybeFeatureName);
}

function isBooleanFeature(
  possibleValues: readonly FeatureValue<FeatureName>[],
) {
  return (
    possibleValues.includes(true) &&
    possibleValues.includes(false) &&
    possibleValues.length === 2
  );
}
