import { sendEvent } from "src/analytics/sendEvent";
import { setClosedBannerCookie } from "./cookieBanner";
import { setOptOutOfAds } from "./optOutOfAds";
import { getConsentImplementation } from "@rome2rio/tcf-api";

export default class extends Marko.Component<{}, {}> {
  dialog: HTMLDivElement | null = null;

  onMount() {
    // We're using event delegation here to capture clicks/change events that happen
    // on the <cookie-consent>, elements are marked-up with data-action or data-change-action
    // to indicate that their events should be handled by this component.
    this.el?.addEventListener("click", this.handleClick.bind(this));

    getConsentImplementation()
      .then((consentImplementation) => {
        if (!consentImplementation.includes("gdpr")) {
          this.el?.setAttribute("aria-hidden", "false");
        }
      })
      .catch((error: Error) => {
        // If we can't determine if GDPR applies, we'll show the cookie banner
        this.el?.setAttribute("aria-hidden", "false");
        window.Sentry?.captureException(
          error || new Error("Unknown error in cookie banner"),
        );
      });
  }

  handleClick(event: Event) {
    if (event.target) {
      const targetElement = event.target as HTMLElement;
      const closestElement =
        targetElement.closest<HTMLElement>("[data-action]");
      const closestAction = closestElement?.dataset["action"];
      switch (closestAction) {
        case "open":
          this.handleOpen();
          break;
        case "close":
          this.handleClose();
          break;
        case "opt-out":
          this.handleOptOut(event);
          break;
      }
    }
  }

  handleOptOut(event: Event) {
    sendEvent({
      category: "AlertBanner",
      action: "Clicked:AdsOptOut",
    });
    event.preventDefault();
    this.handleClose();
    setOptOutOfAds();
    // We need to reload the page to clear any existing ads, and run the logic again.
    window.location.reload();
  }

  handleClose() {
    sendEvent({
      category: "AlertBanner",
      action: "Dismissed",
      label: "preferences || cookie",
    });
    setClosedBannerCookie();
    this.el
      ?.querySelector("[role=dialog]")
      ?.setAttribute("aria-hidden", "true");
  }

  handleOpen() {
    sendEvent({
      category: "AlertBanner",
      action: "Shown",
      label: "preferences || cookie",
    });

    this.el
      ?.querySelector("[role=dialog]")
      ?.setAttribute("aria-hidden", "false");
  }
}
