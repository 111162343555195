import type { ExperimentConfig } from "./ExperimentConfig";

export function getExperimentConfigString(
  experimentConfig: Partial<ExperimentConfig>,
) {
  return Object.entries(experimentConfig)
    .map(([experimentName, experimentVariant]) => {
      return `${experimentName}:${experimentVariant}`;
    })
    .join(",");
}
