import { messages } from "../r2r-footer.messages";
import { getConsentImplementation } from "@rome2rio/tcf-api";
import type { ConsentRegulations } from "@rome2rio/tcf-api";

class {
  declare state: { showDoNotSellLink: boolean; optedOut: boolean };

  onCreate() {
    this.state = { showDoNotSellLink: false, optedOut: false };
  }
  onMount() {
    window.googlefc?.callbackQueue?.push({
      INITIAL_CCPA_DATA_READY: () => {
        this.state.optedOut =
          window.googlefc?.ccpa?.getInitialCcpaStatus() ===
          window.googlefc?.ccpa?.InitialCcpaStatusEnum.OPTED_OUT;
      },
    });

    getConsentImplementation()
      .then((consentImplementation: ConsentRegulations[]) => {
        this.state.showDoNotSellLink =
          consentImplementation.includes("usprivacy");
      })
      .catch((error: Error) => {
        window.Sentry?.captureException(
          error || new Error("Unknown error in getConsentImplementation"),
        );
      });
  }
  handleClick() {
    window.googlefc?.ccpa?.openConfirmationDialog((userOptedOut: boolean) => {
      this.state.optedOut = userOptedOut;
    });
  }
}

/*
 * To ensure we don't re-render formatted-message,
 * we use no-update-body and instead hide the element when the consent message is not shown
 */
<li class="inline-block font-light leading-5 md:w-full">
  <span
    no-update-body
    hidden=!state.optedOut
    class="border-b border-b-neutral-600 text-sm font-light"
  >
    <formatted-message no-update message=messages.manageUSConsentOptedOut />
  </span>
  <button
    class="underline decoration-neutral-600 text-sm font-light hover:border-b-rome2rio-pink cursor-pointer text-left"
    data-log="on-click"
    data-category="FooterClicked"
    data-action="Click:ManageUSConsent"
    on-click("handleClick")
    hidden=(!state.showDoNotSellLink || state.optedOut)
    no-update-body
  >
    <formatted-message no-update message=messages.manageUSConsentLink />
  </button>
</li>
