import { defineMessages } from "@formatjs/intl";

export const messages = defineMessages({
  discoverHeading: {
    id: "footer.discover_heading",
    defaultMessage: "Discover",
    description: "Heading for the discover section",
  },
  ticketsLink: {
    id: "footer.tickets_link",
    defaultMessage: "Tickets",
    description: "Link that goes to the tickets product page",
  },
  hotelsLink: {
    id: "footer.hotels_link",
    defaultMessage: "Hotels",
    description: "Link that goes to the hotels product page",
  },
  rentalCarsLink: {
    id: "footer.rental_cars_link",
    defaultMessage: "Rental Cars",
    description: "Link that goes to rental cars",
  },
  travelGuidesLink: {
    id: "footer.travel_guides_link",
    defaultMessage: "Travel Guides",
    description: "Link that goes to travel guides",
  },
  blogLink: {
    id: "footer.blog_link",
    defaultMessage: "Blog",
    description: "Link that goes to the blog",
  },
  aboutUsLink: {
    id: "footer.about_us_link",
    defaultMessage: "About Us",
    description: "Link that goes to the about us page",
  },
  pressLink: {
    id: "footer.press_link",
    defaultMessage: "Press",
    description: "Link that goes to the press page",
  },
  careersLink: {
    id: "footer.careers_link",
    defaultMessage: "Careers",
    description: "Link that goes to the careers page",
  },
  helpLink: {
    id: "footer.help_link",
    defaultMessage: "Help",
    description: "Link that goes to the help page",
  },
  contactUsLink: {
    id: "footer.contact_us_link",
    defaultMessage: "Contact Us",
    description: "Link that goes to the contact us page",
  },
  appsHeading: {
    id: "footer.apps_heading",
    defaultMessage: "Apps",
    description: "Heading for the apps section",
  },
  photoCreditsLink: {
    id: "footer.photo_credits_link",
    defaultMessage: "Photo Credits",
    description: "Link that goes to the photo credits legal page",
  },
  dataSourcesLink: {
    id: "footer.data_sources_link",
    defaultMessage: "Data Sources",
    description: "Link that goes to the data sources legal page",
  },
  euOnlineDisputeLink: {
    id: "footer.eu_online_dispute_link",
    defaultMessage: "EU Online Dispute",
    description:
      "Link that goes to the EU online dispute resolution legal page",
  },
  manageConsentLink: {
    id: "footer.manage_consent_link",
    defaultMessage: "Privacy Settings",
    description: "Link that opens the GDPR consent management popup",
  },
  manageUSConsentLink: {
    id: "footer.manage_us_consent_link",
    defaultMessage: "Do not sell or share my personal information",
    description: "Link that opens the US consent management popup",
  },
  manageUSConsentOptedOut: {
    id: "footer.manage_us_consent_opted_out",
    defaultMessage:
      "You have opted out of selling or sharing your personal information",
    description:
      "Message displayed when user has opted out of the sale of their personal information.",
  },
});
