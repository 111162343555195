import Cookies from "js-cookie";
import { getCookie } from "src/utils/getCookie";

// The following names and values matches with the existing landing pages.
const CLOSED_BANNER_COOKIE_NAME = "closed_banner_preferences";
const CLOSED_BANNER_TRUE_VALUE = "1";
/**
 * @returns whether or not the user has closed or not the banner.
 */
export function hasClosedBannerCookie(request?: Request): boolean {
  const closedBannerCookie = getCookie(
    // TODO(@AndresBarreto-code): Once the features flags are implemented, we need to add the type to the cookie name.
    // Then cookie will have two possible name: closed_banner_preferences, closed_banner_cookie.
    CLOSED_BANNER_COOKIE_NAME,
    request,
  );
  return closedBannerCookie !== CLOSED_BANNER_TRUE_VALUE;
}

export function setClosedBannerCookie() {
  const tenYearsInDays = 10 * 365;
  Cookies.set(CLOSED_BANNER_COOKIE_NAME, CLOSED_BANNER_TRUE_VALUE, {
    expires: tenYearsInDays,
  });
}
