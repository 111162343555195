import type { FeatureFilterFunc } from "./FeatureFilter";
import type { Context } from "@marko/run";

export function and(...rest: FeatureFilterFunc[]): FeatureFilterFunc {
  return (context: Context) => {
    for (const filter of rest) {
      const result = filter(context);
      if (!result) {
        return undefined;
      }
    }
    return true;
  };
}
