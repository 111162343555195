import { parse } from "cookie";

/**
 * Get the value of a cookie on the server or browser.
 *
 * @param name Name of the cookie to get.
 * @param request Request on the server, or undefined on the browser.
 * @returns The value of the cookie, or undefined if the cookie is not set.
 */
export function getCookie(name: string, request?: Request): string | undefined {
  return getCookies(request)[name];
}

function getCookies(request?: Request): Partial<Record<string, string>> {
  let cookieString: string | undefined | null;
  if (typeof window !== "undefined") {
    cookieString = document.cookie;
  } else {
    cookieString = request?.headers.get("cookie");
  }

  return cookieString ? parse(cookieString) : {};
}
