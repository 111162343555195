import Cookies from "js-cookie";
import { getCookie } from "src/utils/getCookie";

const POPUNDER_COOKIE = "popunder";
const POPUNDER_RATE_LIMIT = 30 * 60 * 1000; // 30 minutes in milliseconds

interface PopunderCookie {
  providers: Record<string, { lastShown: Date }>;
}

export function getIsPopunderRateLimited(request?: Request): boolean {
  const cookie = getPopunderCookie(request);
  const rateLimitCutOff = new Date(Date.now() - POPUNDER_RATE_LIMIT);

  return Object.values(cookie.providers).some(
    (val) => val.lastShown > rateLimitCutOff,
  );
}

export function getPopunderCookie(request?: Request): PopunderCookie {
  const cookieStr = getCookie(POPUNDER_COOKIE, request);
  if (cookieStr === undefined) {
    return { providers: {} };
  }

  const cookie = JSON.parse(decodeURIComponent(cookieStr)) as PopunderCookie;
  if (cookie.providers === undefined) {
    return { providers: {} };
  }

  return cookie;
}

export function updateCookie(provider: string) {
  const cookie = JSON.parse(
    Cookies.get(POPUNDER_COOKIE) || "{}",
  ) as PopunderCookie;

  cookie.providers = {
    ...cookie.providers,
    [provider]: { lastShown: new Date() },
  };

  Cookies.set(POPUNDER_COOKIE, JSON.stringify(cookie), { expires: 30 });
}

export function clearPopunderCookie() {
  Cookies.remove(POPUNDER_COOKIE);
}
